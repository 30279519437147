import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { IRegisteredProductDataItem } from '../products';
import { ProductsService, SyneriseService, UserService } from '../services';
import { IUser, IUserUpdateDto, IUserUpdateFormModel } from '../user';
import { catchError, concatMap, finalize, tap } from 'rxjs/operators';
import { INgxsFormModel } from '../abstract';
import { ToastrService } from 'ngx-toastr';
import { ISynUserUpdateDto, SyneriseActionEnum } from '../synerise';

export class UserStateSetJwtToken {
  public static readonly type = '[UserState] set jwt token';

  constructor(
    public readonly payload: {
      token: string;
      tokenExpiredAt: string;
    },
  ) {}
}

export class UserStateSetSsoState {
  public static readonly type = '[UserState] set sso state';

  constructor(
    public readonly payload: {
      state: string;
    },
  ) {}
}

export class UserStateSetNumberOfViewsAboutProductRegistration {
  public static readonly type = '[UserState] set number of views about product registration';

  constructor(
    public readonly payload: {
      numberOfViews: string;
    },
  ) {}
}

export class UserStateFetchRegisteredProducts {
  public static readonly type = '[UserState] fetch registered products';

  constructor(
    public readonly payload: {
      refresh?: boolean;
    },
  ) {}
}

export class UserStateLogout {
  public static readonly type = '[UserState] logout';
}

export class UserStateSetUserData {
  public static readonly type = '[UserState] set user data';

  constructor(public readonly payload: { user: IUser }) {}
}

export class UserStateRecaptchaScriptLoaded {
  public static readonly type = '[UserState] recaptcha script loaded';
}

export class UserStateUpdateAddressData {
  public static readonly type = '[UserState] update address data';
}

export class UserStateValidateMobile {
  public static readonly type = '[UserState] validate mobile';

  constructor(public readonly payload: { mobile: string; mobile_prefix: string }) {}
}

export class UserStateValidateMobileSmsCode {
  public static readonly type = '[UserState] validate mobile sms code';

  constructor(public readonly payload: { code: string }) {}
}

export interface UserStateModel {
  loading: boolean;
  token: string;
  state: string | null;
  tokenExpiredAt: string;
  numberOfViews: string;
  recaptchaScriptLoaded: boolean;
  user: IUser;
  userEditForm: INgxsFormModel<IUserUpdateFormModel>;
  registeredProducts: IRegisteredProductDataItem[];
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    loading: false,
    token: '',
    state: null,
    tokenExpiredAt: '',
    numberOfViews: '0',
    recaptchaScriptLoaded: false,
    user: null,
    userEditForm: null,
    registeredProducts: null,
  },
})
@Injectable()
export class UserState {
  constructor(
    private readonly _userService: UserService,
    private readonly _productsService: ProductsService,
    private readonly _toastrService: ToastrService,
    private readonly _syneriseService: SyneriseService,
  ) {}

  @Selector()
  public static loading({ loading }: UserStateModel): boolean {
    return loading;
  }

  @Selector()
  public static registeredProducts({ registeredProducts }: UserStateModel): IRegisteredProductDataItem[] {
    return registeredProducts;
  }

  @Selector()
  public static token({ token }: UserStateModel): string {
    return token;
  }

  @Selector()
  public static state({ state }: UserStateModel): string | null {
    return state;
  }

  @Selector()
  public static tokenExpiredAt({ tokenExpiredAt }: UserStateModel): string {
    return tokenExpiredAt;
  }

  @Selector()
  public static user({ user }: UserStateModel): IUser {
    return user;
  }

  @Selector()
  public static numberOfViews({ numberOfViews }: UserStateModel): string {
    return numberOfViews;
  }

  @Selector()
  public static recaptchaScriptLoaded({ recaptchaScriptLoaded }: UserStateModel): boolean {
    return recaptchaScriptLoaded;
  }

  @Action(UserStateSetJwtToken)
  public setJwtToken(
    { patchState }: StateContext<UserStateModel>,
    { payload: { token, tokenExpiredAt } }: UserStateSetJwtToken,
  ): void {
    patchState({ token, tokenExpiredAt });
  }

  @Action(UserStateSetSsoState)
  public setSsoState({ patchState }: StateContext<UserStateModel>, { payload: { state } }: UserStateSetSsoState): void {
    patchState({ state });
  }

  @Action(UserStateSetNumberOfViewsAboutProductRegistration)
  public setInfoAboutProductRegistrationDisplayed(
    { patchState }: StateContext<UserStateModel>,
    { payload: { numberOfViews } }: UserStateSetNumberOfViewsAboutProductRegistration,
  ): Observable<UserStateModel> {
    return of(patchState({ numberOfViews }));
  }

  @Action(UserStateSetUserData)
  public setUserData(
    { patchState }: StateContext<UserStateModel>,
    { payload: { user } }: UserStateSetUserData,
  ): Observable<UserStateModel> {
    return of(
      patchState({
        user,
        userEditForm: {
          model: {
            flatNumber: user.address?.flat_number,
            zipCode: user.address?.zip_code,
            houseNumber: user.address?.house_number,
            city: user.address?.city,
            street: user.address?.street,
          },
          errors: {},
          dirty: false,
          disabled: false,
          status: 'pristine',
        },
      }),
    );
  }

  @Action(UserStateLogout)
  public logout({ patchState }: StateContext<UserStateModel>): void {
    patchState({ token: null, tokenExpiredAt: null, user: null });
  }

  @Action(UserStateFetchRegisteredProducts)
  public fetchRegisteredProducts(
    { patchState, getState }: StateContext<UserStateModel>,
    { payload: { refresh } }: UserStateFetchRegisteredProducts,
  ): Observable<IRegisteredProductDataItem[]> {
    const { registeredProducts, loading, user } = getState();
    if (!user) {
      return of([]);
    }
    if (loading) {
      return of([]);
    }
    if (!refresh && registeredProducts) {
      return of(registeredProducts);
    }
    patchState({ loading: true });
    return this._productsService.fetchRegisteredProducts().pipe(
      tap((registeredProducts) => {
        patchState({ registeredProducts });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(UserStateRecaptchaScriptLoaded)
  public recaptchaScriptLoaded({ patchState }: StateContext<UserStateModel>): void {
    patchState({ recaptchaScriptLoaded: true });
  }

  @Action(UserStateUpdateAddressData)
  public updateAddressData({ patchState, getState, dispatch }: StateContext<UserStateModel>): Observable<IUser> {
    const {
      token,
      userEditForm: {
        model: { zipCode, street, houseNumber, flatNumber, city },
      },
    } = getState();

    const userUpdateDto: IUserUpdateDto = {
      zipCode,
      street,
      houseNumber,
      flatNumber,
      city,
    };

    patchState({ loading: true });

    const _sendSynFormData = (userUpdateDto: IUserUpdateDto) => {
      const synUserUpdateDto: ISynUserUpdateDto = {
        flatNumber: userUpdateDto.flatNumber,
        zipCode: userUpdateDto.zipCode,
        houseNumber: userUpdateDto.houseNumber,
        city: userUpdateDto.city,
        street: userUpdateDto.street,
      };
      this._syneriseService.sendFormData(SyneriseActionEnum.UPDATE_USER_DATA, synUserUpdateDto);
    };

    return this._userService.updateAddress(userUpdateDto).pipe(
      concatMap(() =>
        this._userService.getMe(token).pipe(
          tap((user: IUser) => {
            dispatch(new UserStateSetUserData({ user }));
            patchState({ loading: false });
          }),
        ),
      ),
      finalize(() => {
        _sendSynFormData(userUpdateDto);
        patchState({ loading: false });
      }),
    );
  }

  @Action(UserStateValidateMobile)
  public validateMobile(
    { patchState }: StateContext<UserStateModel>,
    { payload: { mobile, mobile_prefix } }: UserStateValidateMobile,
  ): Observable<void> {
    patchState({ loading: true });
    return this._userService.validateMobile(mobile, mobile_prefix).pipe(
      catchError((error) => {
        if (error?.error?.code === 400 && error?.error?.errors?.children?.mobile?.errors[0]) {
          this._toastrService.error(error?.error?.errors?.children?.mobile?.errors[0]);
        }
        return throwError(error);
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(UserStateValidateMobileSmsCode)
  public validateMobileSmsCode(
    { patchState, getState, dispatch }: StateContext<UserStateModel>,
    { payload: { code } }: UserStateValidateMobileSmsCode,
  ): Observable<IUser> {
    patchState({ loading: true });
    return this._userService.validateMobileSmsCode(code).pipe(
      concatMap(() =>
        this._userService.getMe(getState().token).pipe(
          tap((user: IUser) => {
            dispatch(new UserStateSetUserData({ user }));
            patchState({ loading: false });
          }),
        ),
      ),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }
}
